import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { ReactNode } from 'react';
import InfoSvg from '@img/circle-info-solid.svg';
import PersonSvg from '@img/person.svg';
import PartnerSvg from '@img/partner.svg';
import ChildSvg from '@img/child.svg';
import ReactTooltip from 'react-tooltip';
import Stars from '@components/stars/stars';
import Checked from '@img/checked.svg';
import Button from '@components/button/button';
import config from '../../../config.json';

export type RankingItemProps = {
  czasTrwaniaUmowy: string;
  minimalnaSkladka: string;
  nazwaTowarzystwa: string;
  ocena: number;
  osobyUbezpieczone: ['ubezpieczajacy' | 'partner' | 'dzieci'];
  pakiet: string;
  sumaUbezpieczenia: string;
  wazneCechy: string[];
  wiekWstepuDziecka: string;
  wiekWstepuRodzica: string;
  logo: {
    localFile: IGatsbyImageData;
  };
  kategoria: 'zycie' | 'posag';
  slugDoArtykulu?: string;
  pozycja: number;
  index: number;
};
export default function RankingItem({
  czasTrwaniaUmowy,
  minimalnaSkladka,
  nazwaTowarzystwa,
  ocena,
  osobyUbezpieczone,
  pakiet,
  sumaUbezpieczenia,
  wazneCechy,
  wiekWstepuDziecka,
  wiekWstepuRodzica,
  logo,
  kategoria,
  slugDoArtykulu,
  index,
}: RankingItemProps) {
  const image = getImage(logo.localFile);
  return (
    <div
      className={`relative flex w-full rounded-3xl border-t-8  shadow-light  ${
        index === 0 ? 'border-green bg-green' : 'border-text bg-text'
      }`}
    >
      {index === 0 && (
        <div className="absolute -top-4 left-[22px] whitespace-nowrap rounded-tr-2xl rounded-br-2xl bg-[#00a51b] px-6 py-1 text-base font-bold text-white lg:top-auto lg:-left-2 lg:bottom-10">
          Najbardziej popularna oferta
          <span className="absolute -bottom-[17px] left-0 rotate-90">
            <div className="inline-block  w-3 overflow-hidden">
              <div className="h-2 origin-bottom-left -rotate-45 transform bg-[#005d0f]"></div>
            </div>
          </span>
        </div>
      )}
      <ReactTooltip className="!bg-text !text-white" />
      <div
        className={`h-full px-1 pt-[120px] text-[40px] font-bold text-white lg:px-3  ${
          index === 0 ? 'border-green' : 'border-text'
        }`}
      >
        {index + 1}
      </div>
      <div
        className={`w-full rounded-2xl border-t bg-white py-4 px-3 lg:px-6 ${
          index === 0 ? 'border-green' : 'border-text'
        }`}
      >
        <div className="flex flex-col items-start lg:flex-row lg:items-center lg:justify-between">
          <div className="flex flex-col">
            <h2 className="mt-4 text-3xl font-bold lg:mt-0">
              {nazwaTowarzystwa}
            </h2>
            <span className="mt-0 text-lg">{pakiet}</span>
          </div>
          <div className="flex w-full flex-col items-end lg:w-fit lg:flex-row  lg:items-center">
            <div className="order-2 mt-2 lg:order-1 lg:mt-0">
              <Stars rating={ocena} />
            </div>
            <div className=" order-1 ml-6 rounded-lg bg-text px-4 py-2 text-5xl font-black text-white lg:order-2">
              {ocena}
            </div>
          </div>
        </div>
        <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
          <div className="text-center md:text-left lg:row-span-2">
            {image && <GatsbyImage image={image} alt={nazwaTowarzystwa} />}
          </div>
          <Box title="Suma ubezpieczenia">{sumaUbezpieczenia}</Box>
          <Box title="Czas trwania umowy">{czasTrwaniaUmowy}</Box>
          <Box title="Minimalna składka">{minimalnaSkladka}</Box>
          <Box title="Wiek wstępu dziecka">{wiekWstepuDziecka}</Box>
          <Box title="Wiek wstępu rodzica">{wiekWstepuRodzica}</Box>
          <Box title="Osoby ubezpieczone">
            <div className="flex items-center gap-x-3">
              {osobyUbezpieczone.map((item) => {
                switch (item) {
                  case 'ubezpieczajacy':
                    return (
                      <div className="flex h-12 items-end">
                        <img src={PersonSvg} alt="" className="h-full" />
                        <img
                          src={InfoSvg}
                          alt=""
                          className="inline w-4"
                          data-tip="Ubezpieczający"
                          data-multiline={true}
                          data-place="bottom"
                        />
                      </div>
                    );
                  case 'partner':
                    return (
                      <div className="flex h-12 items-end">
                        <img src={PartnerSvg} alt="" className="h-full" />
                        <img
                          src={InfoSvg}
                          alt=""
                          className="inline w-4"
                          data-tip="Partner/ka"
                          data-multiline={true}
                          data-place="bottom"
                        />
                      </div>
                    );
                  case 'dzieci':
                    return (
                      <div className="flex h-12 items-end">
                        <div className="flex h-full items-center">
                          <img src={ChildSvg} alt="" className="h-7" />
                        </div>
                        <img
                          src={InfoSvg}
                          alt=""
                          className="inline w-4"
                          data-tip="Dzieci"
                          data-multiline={true}
                          data-place="bottom"
                        />
                      </div>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </Box>
        </div>
        <div className="mt-6 flex w-full flex-col lg:flex-row">
          <span className="mr-2 whitespace-nowrap font-bold">Ważne cechy:</span>
          <div className="flex flex-wrap gap-x-2">
            {wazneCechy.map((item) => (
              <span className="flex gap-x-1 gap-y-2 lowercase">
                <img src={Checked} alt="" />
                {item}
              </span>
            ))}
          </div>
        </div>
        <div className="mt-8 mb-4 flex w-full flex-col items-center justify-end md:flex-row">
          {slugDoArtykulu && (
            <Button
              to={`/towarzystwo-ubezpieczeniowe/${slugDoArtykulu}`}
              visualType="tertiary"
              className="!text-md mb-4 !normal-case md:mb-0"
            >
              Poznaj szczegóły
            </Button>
          )}
          <a href={config.CALC_URL} target="_blank">
            <Button visualType="primary">Porównaj oferty</Button>
          </a>
        </div>
      </div>
    </div>
  );
}

const Box = ({ children, title }: { children: ReactNode; title: string }) => {
  return (
    <p className="flex min-h-[90px] flex-col justify-center rounded-xl px-4 py-2 text-2xl font-black shadow-light">
      {children}
      <span className="text-base font-normal">{title}</span>
    </p>
  );
};
