import React from 'react';

import Star0 from '@img/star-0.svg';
import Star1 from '@img/star-1.svg';
import Star2 from '@img/star-2.svg';
import Star3 from '@img/star-3.svg';
import Star4 from '@img/star-4.svg';
import Star5 from '@img/star-5.svg';

export default function Stars({ rating }: { rating: number }) {
  var starArr = [];
  const rate = Math.round((rating / 2) * 10) / 10;
  for (let i = 1; i <= 5; i++) {
    if (i <= rate) {
      starArr.push(Star5);
    }
    if (i > rate) {
      let r = '' + rate;
      const rInt = parseInt(r.split('.', 2)[1]);
      if (rInt >= 8 && rInt < 10) starArr.push(Star4);
      if (rInt >= 6 && rInt < 8) starArr.push(Star3);
      if (rInt >= 4 && rInt < 6) starArr.push(Star2);
      if (rInt >= 0 && rInt < 4) starArr.push(Star1);
      break;
    }
  }
  starArr = Object.assign(new Array(5).fill(Star0), starArr);

  return (
    <div>
      <div className="flex items-center">
        {starArr.map((star, i) => {
          return <img src={star} key={i} className="mr-2 h-7" alt="" />;
        })}
      </div>
    </div>
  );
}
