import React from 'react';
import Heading from '../components/heading/heading';
import Layout from '../components/layout/layout';
import SectionTag from '../components/section-tag/section-tag';
import { graphql } from 'gatsby';
import RankingItem, {
  RankingItemProps,
} from '../components/ranking-item/ranking-item';
import Seo from '../components/seo/seo';
import Guides from '@domain/guides/guides';
import config from '../../config.json';
import { getImage } from 'gatsby-plugin-image';

type Props = {
  data: {
    allWpUbezpieczenie: {
      edges: {
        node: { ubezpieczenia: RankingItemProps };
      }[];
    };
    allWpPost: any;
    wpPage: any;
  };
};

const Ranking = ({ data }: Props) => {
  const [isLifeInsurance, setIsLifeInsurance] = React.useState(true);

  const rankingZycie = data.allWpUbezpieczenie.edges
    .filter((edge) => {
      return edge.node.ubezpieczenia.kategoria === 'zycie';
    })
    .sort((a, b) => {
      return a.node.ubezpieczenia.pozycja - b.node.ubezpieczenia.pozycja;
    });

  const rankingPosag = data.allWpUbezpieczenie.edges
    .filter((edge) => {
      return edge.node.ubezpieczenia.kategoria === 'posag';
    })
    .sort((a, b) => {
      return a.node.ubezpieczenia.pozycja - b.node.ubezpieczenia.pozycja;
    });

  const posts = data.allWpPost.edges;
  const wpPage = data.wpPage;

  // Elementy rozszerzone dla wyszukiwarki
  const ratingStructuredData = rankingZycie.map((edge) => {
    const image = getImage(edge.node.ubezpieczenia.logo.localFile);
    return {
      '@context': 'https://schema.org/',
      '@type': 'Review',
      itemReviewed: {
        '@type': 'Product',
        name: edge.node.ubezpieczenia.nazwaTowarzystwa,
        brand: {
          '@type': 'Brand',
          name: edge.node.ubezpieczenia.nazwaTowarzystwa,
        },
        image: `${config.SITE_URL}${image?.images.fallback?.src}`,
        description: edge.node.ubezpieczenia.pakiet,
        review: {
          '@type': 'Review',
          reviewRating: {
            '@type': 'Rating',
            ratingValue: `${edge.node.ubezpieczenia.ocena / 2}`,
            bestRating: '5',
          },
          author: {
            '@type': 'Organization',
            name: 'Startwprzyszlosc.pl',
          },
        },
      },
      reviewRating: {
        '@type': 'Rating',
        ratingValue: `${edge.node.ubezpieczenia.ocena / 2}`,
        bestRating: '5',
      },
      name: edge.node.ubezpieczenia.nazwaTowarzystwa,
      author: {
        '@type': 'Organization',
        name: 'Startwprzyszlosc.pl',
      },
      reviewBody: edge.node.ubezpieczenia.pakiet,
      publisher: {
        '@type': 'Organization',
        name: 'LeadGuru Sp. z o.o.',
      },
    };
  });

  return (
    <Layout>
      <Seo
        title={wpPage.seo.title}
        description={wpPage.seo.metaDesc}
        keywords={wpPage.seo.focuskw}
        pathname="/ranking-polis-mieszkaniowych/"
        jsonLd={JSON.stringify(ratingStructuredData)}
      />

      <div className="bg-blueLight">
        <div className="container mx-auto px-4 py-6 lg:pt-20 lg:pb-0">
          <div className="flex flex-col items-center justify-center text-center">
            <SectionTag tag="h1">PORÓWNAJ POLISY</SectionTag>
            <Heading tag="h1" className="lg:!text-[42px]">
              Ranking ubezpieczeń dla dzieci
            </Heading>
            <p className="font-secondary mt-4 max-w-xl text-lg lg:mt-7">
              Nie wiesz, jakie ubezpieczenie będzie najlepsze dla Twojego
              dziecka? Porównaj oferty ubezpieczeniowe największych towarzystw w
              Polsce. Dzięki naszemu rankingowi to dziecinnie proste.
            </p>
          </div>
        </div>
        <div className="relative mt-10 w-full bg-[#FAF1FE] ">
          <div className="relative -top-5 flex w-full justify-center gap-x-2">
            <button
              className={`${
                isLifeInsurance
                  ? 'bg-[#FAF1FE] text-secondary'
                  : 'bg-white text-text'
              } rounded-lg px-2 py-2 lg:px-5 `}
              onClick={() => setIsLifeInsurance(true)}
            >
              Ubezpieczenie na życie
            </button>
            <button
              className={`${
                !isLifeInsurance
                  ? 'bg-[#FAF1FE] text-secondary'
                  : 'bg-white text-text'
              } rounded-xl px-2 py-2 lg:px-5`}
              onClick={() => setIsLifeInsurance(false)}
            >
              Ubezpieczenie posagowe
            </button>
          </div>
          <div className="container mx-auto px-4 py-6 lg:pt-0 lg:pb-5">
            <div className="mx-auto my-10 flex max-w-[1000px] flex-col gap-y-7">
              {isLifeInsurance
                ? rankingZycie.map((policy, i) => {
                    return (
                      <RankingItem
                        key={i}
                        {...policy.node.ubezpieczenia}
                        index={i}
                      />
                    );
                  })
                : rankingPosag.map((policy, i) => {
                    return (
                      <RankingItem
                        key={i}
                        {...policy.node.ubezpieczenia}
                        index={i}
                      />
                    );
                  })}
            </div>
          </div>
        </div>
        <Guides posts={posts} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query rankingUbezpieczen {
    allWpUbezpieczenie {
      edges {
        node {
          ubezpieczenia {
            czasTrwaniaUmowy
            minimalnaSkladka
            nazwaTowarzystwa
            ocena
            osobyUbezpieczone
            pakiet
            sumaUbezpieczenia
            wazneCechy
            wiekWstepuDziecka
            wiekWstepuRodzica
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    height: 65
                    formats: [AUTO]
                    webpOptions: { quality: 90 }
                    quality: 90
                    placeholder: TRACED_SVG
                  )
                }
              }
            }
            kategoria
            pozycja
            slugDoArtykulu
          }
        }
      }
    }

    allWpPost(
      sort: { order: DESC, fields: dateGmt }
      filter: {
        categories: {
          nodes: {
            elemMatch: {
              slug: {
                in: ["pozostale", "bezpieczenstwo", "edukacja", "oszczedzanie"]
              }
            }
          }
        }
      }
      limit: 6
    ) {
      edges {
        node {
          dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
          id
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750, height: 400, quality: 90)
                }
              }
            }
          }
          modifiedGmt(formatString: "DD.MM.YYYY")
          zajawkaBlog {
            zajawka
          }
          content
          categories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
    wpPage(slug: { eq: "ranking" }) {
      seo {
        title
        focuskw
        metaDesc
      }
    }
  }
`;

export default Ranking;
